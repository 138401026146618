/* for AlpineJs */
[x-cloak] {
    display: none !important;
}

@layer utilities {

    /* Text Shadow */
    .text-shadow { text-shadow: 0 2px 4px rgba(0,0,0,0.10) }
    .text-shadow-md { text-shadow: 0 4px 8px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.08) }
    .text-shadow-lg { text-shadow: 0 15px 30px rgba(0,0,0,0.11), 0 5px 15px rgba(0,0,0,0.08) }
    .text-shadow-none { text-shadow: none }

    // Header block
    .header__block {
		@apply flex items-center justify-center max-w-md pl-4 pr-8 mx-auto text-white sm:max-w-lg sm:px-6 lg:max-w-7xl lg:px-8 gap-x-4;
	}

    // Hero Container
    .container {

        @apply p-6 sm:p-8 lg:p-12 lg:py-16;

        &-hero {
            @apply container max-w-screen-2xl mx-auto xl:px-16;
        }
    
        // Block Container
        &-block {
            @apply container max-w-screen-xl mx-auto;
        }
    }

    // Google Maps
    .gm-map {
        @apply w-full h-full;
    }

    /* Accent: Pink */
    :root {
        --color-accent-50: 251 244 247;
        --color-accent-100: 246 234 240;
        --color-accent-200: 236 218 227;
        --color-accent-300: 222 196 210;
        --color-accent-400: 200 171 186;
        --color-accent-500: 175 142 159;
        --color-accent-600: 157 108 133;
        --color-accent-700: 138 81 111;
        --color-accent-800: 123 61 93;
        --color-accent-900: 108 51 80;
    }

    /* Accent: Yellow */
    .theme-yellow {
        --color-accent-50: 254 249 236;
        --color-accent-100: 253 240 201;
        --color-accent-200: 252 226 146;
        --color-accent-300: 251 208 81;
        --color-accent-400: 247 192 27;
        --color-accent-500: 233 177 12;
        --color-accent-600: 202 153 7;
        --color-accent-700: 164 125 10;
        --color-accent-800: 136 106 17;
        --color-accent-900: 117 93 21;
    }

    /* Accent: Blue */
    .theme-blue {
        --color-accent-50: 243 250 252;
        --color-accent-100: 231 245 249;
        --color-accent-200: 206 235 243;
        --color-accent-300: 161 217 231;
        --color-accent-400: 108 196 218;
        --color-accent-500: 55 175 205;
        --color-accent-600: 42 142 167;
        --color-accent-700: 35 118 139;
        --color-accent-800: 29 97 114;
        --color-accent-900: 25 83 98;
    }

}