/* ==========================================================================
	 Breakpoint (< Mixins < Tools)
	 ========================================================================== */

@import "~breakpoint-sass";

@mixin below($point) {
    @include breakpoint(max-width $point - 1) {
        @content;
    }
}

@mixin above($point) {
    @include breakpoint($point) {
        @content;
    }
}

@mixin between($point-1, $point-2) {
    @include breakpoint($point-1 $point-2 - 1) {
        @content;
    }
}
