/*
 * Button
 */

@layer utilities {

	// Button
	.btn {

		// Basic Classes
		@apply block w-auto text-center transition border border-transparent rounded-none sm:inline-block focus:outline-0 focus:border-transparent;

		// Sizing
		&.btn-sm { @apply px-2 py-1 body-sm font-semibold }
		&.btn-md { @apply px-4 py-2 body-md font-semibold }
		&.btn-lg { @apply px-6 py-3 body-lg font-semibold }

		// Filled Buttons
		&.btn-filled {

			@apply focus:ring-2;
			
			// Colour
			&.btn-primary { @apply text-primary-50 bg-primary-900 hover:bg-primary-800 active:bg-primary-700 focus:ring-primary-900/25 }
			&.btn-secondary { @apply text-secondary-50 bg-secondary-400 hover:bg-secondary-500 active:bg-secondary-300 focus:ring-secondary-400/25 }
			&.btn-accent { @apply text-accent-900 bg-accent-300 hover:bg-accent-400 active:bg-accent-200 focus:ring-accent-300/25 }
			&.btn-light { @apply text-primary-900 bg-primary-50 hover:bg-primary-100 active:bg-white focus:ring-primary-300/25 }

		}

		// Line Buttons
		&.btn-line {

			@apply focus:ring-2;
			
			// Colour
			&.btn-primary { @apply text-primary-900 bg-none border-2 border-primary-900 hover:border-primary-800 active:border-primary-700 focus:ring-primary-800/25 }
			&.btn-secondary { @apply text-secondary-400 bg-none border-2 border-secondary-400 hover:border-secondary-500 active:border-secondary-300 focus:ring-secondary-400/25 }
			&.btn-accent { @apply text-accent-300 bg-none border-2 border-accent-300 hover:border-accent-400 active:border-accent-200 focus:ring-accent-300/25 }
			&.btn-light { @apply text-primary-50 bg-none border-2 border-primary-50 hover:border-primary-100 active:border-white focus:ring-primary-100/25 }
		}

		// Link Buttons
		&.btn-link {

			@apply p-0;
	
			// Colour
			&.btn-primary { @apply text-primary-900 hover:text-primary-800 active:text-primary-600 focus:text-primary-700 }
			&.btn-secondary { @apply text-secondary-400 hover:text-secondary-500 active:text-secondary-200 focus:text-secondary-300 }
			&.btn-accent { @apply text-accent-300 hover:text-accent-400 active:text-accent-100 focus:text-accent-200 }
			&.btn-light { @apply text-primary-50 hover:text-primary-100 active:text-white focus:text-primary-200 }
		}
	}
}