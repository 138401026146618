/* ==========================================================================
	 Breakpoint (< Mixins < Tools)
	 ========================================================================== */
@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Berthold";
  src: url("../fonts/Berthold/bertholdakzidenz-webfont.woff2") format("woff2"), url("../fonts/Berthold/bertholdakzidenz-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Glober";
  src: url("../fonts/Glober/globerbold-webfont.woff2") format("woff2"), url("../fonts/Glober/globerbold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Glober";
  src: url("../fonts/Glober/globersemibold-webfont.woff2") format("woff2"), url("../fonts/Glober/globersemibold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Glober";
  src: url("../fonts/Glober/globerbook-webfont.woff2") format("woff2"), url("../fonts/Glober/globerbook-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@layer base {
  /* Headings */
  .h1 {
    @apply font-heading tracking-tight text-6xl font-black leading-none;
  }
  .h2 {
    @apply font-heading tracking-tight text-5xl font-bold leading-tight;
  }
  .h3 {
    @apply font-heading tracking-tight text-4xl font-bold leading-tight;
  }
  .h4 {
    @apply font-heading tracking-tight text-3xl font-bold leading-tight;
  }
  .h5 {
    @apply font-heading text-2xl font-semibold leading-tight;
  }
  .h6 {
    @apply font-heading text-lg font-semibold leading-tight;
  }
  /* Body */
  .body-2xl {
    @apply text-2xl font-normal leading-normal;
  }
  .body-xl {
    @apply text-xl font-normal leading-normal;
  }
  .body-lg {
    @apply text-lg font-normal leading-relaxed;
  }
  .body-md {
    @apply text-base font-normal leading-relaxed;
  }
  .body-sm {
    @apply text-sm font-normal leading-relaxed;
  }
  .body-xs {
    @apply text-xs font-normal leading-relaxed;
  }
}
@layer utilities {
  .copy {
    @apply space-y-1.5 lg:space-y-3;
  }
  .copy italic {
    @apply italic;
  }
  .copy bold {
    @apply font-bold;
  }
  .copy a {
    @apply font-bold text-secondary-400 transition hover:text-secondary-500 active:text-secondary-300;
  }
  .copy > ul {
    @apply list-disc list-outside ml-[1.2em];
  }
  .copy > ul li::marker {
    @apply text-secondary-400;
  }
}
/* for AlpineJs */
[x-cloak] {
  display: none !important;
}

@layer utilities {
  /* Text Shadow */
  .text-shadow {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .text-shadow-md {
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.08);
  }
  .text-shadow-lg {
    text-shadow: 0 15px 30px rgba(0, 0, 0, 0.11), 0 5px 15px rgba(0, 0, 0, 0.08);
  }
  .text-shadow-none {
    text-shadow: none;
  }
  .header__block {
    @apply flex items-center justify-center max-w-md pl-4 pr-8 mx-auto text-white sm:max-w-lg sm:px-6 lg:max-w-7xl lg:px-8 gap-x-4;
  }
  .container {
    @apply p-6 sm:p-8 lg:p-12 lg:py-16;
  }
  .container-hero {
    @apply container max-w-screen-2xl mx-auto xl:px-16;
  }
  .container-block {
    @apply container max-w-screen-xl mx-auto;
  }
  .gm-map {
    @apply w-full h-full;
  }
  /* Accent: Pink */
  :root {
    --color-accent-50: 251 244 247;
    --color-accent-100: 246 234 240;
    --color-accent-200: 236 218 227;
    --color-accent-300: 222 196 210;
    --color-accent-400: 200 171 186;
    --color-accent-500: 175 142 159;
    --color-accent-600: 157 108 133;
    --color-accent-700: 138 81 111;
    --color-accent-800: 123 61 93;
    --color-accent-900: 108 51 80;
  }
  /* Accent: Yellow */
  .theme-yellow {
    --color-accent-50: 254 249 236;
    --color-accent-100: 253 240 201;
    --color-accent-200: 252 226 146;
    --color-accent-300: 251 208 81;
    --color-accent-400: 247 192 27;
    --color-accent-500: 233 177 12;
    --color-accent-600: 202 153 7;
    --color-accent-700: 164 125 10;
    --color-accent-800: 136 106 17;
    --color-accent-900: 117 93 21;
  }
  /* Accent: Blue */
  .theme-blue {
    --color-accent-50: 243 250 252;
    --color-accent-100: 231 245 249;
    --color-accent-200: 206 235 243;
    --color-accent-300: 161 217 231;
    --color-accent-400: 108 196 218;
    --color-accent-500: 55 175 205;
    --color-accent-600: 42 142 167;
    --color-accent-700: 35 118 139;
    --color-accent-800: 29 97 114;
    --color-accent-900: 25 83 98;
  }
}
@media print {
  body {
    font-size: 12pt;
    line-height: 1.25em;
  }
  h1 {
    font-size: 36pt;
    line-height: 1.25em;
  }
  h2 {
    font-size: 24pt;
    line-height: 1.25em;
  }
  h3 {
    font-size: 18pt;
    line-height: 1.25em;
  }
  h4 {
    font-size: 14pt;
    line-height: 1.25em;
  }
  body {
    background: none;
  }
  header {
    display: none;
  }
  article a:after {
    content: " [" attr(href) "] ";
  }
  aside a:after {
    display: none;
  }
}
/*
 * Button
 */
@layer utilities {
  .btn {
    @apply block w-auto text-center transition border border-transparent rounded-none sm:inline-block focus:outline-0 focus:border-transparent;
  }
  .btn.btn-sm {
    @apply px-2 py-1 body-sm font-semibold;
  }
  .btn.btn-md {
    @apply px-4 py-2 body-md font-semibold;
  }
  .btn.btn-lg {
    @apply px-6 py-3 body-lg font-semibold;
  }
  .btn.btn-filled {
    @apply focus:ring-2;
  }
  .btn.btn-filled.btn-primary {
    @apply text-primary-50 bg-primary-900 hover:bg-primary-800 active:bg-primary-700 focus:ring-primary-900/25;
  }
  .btn.btn-filled.btn-secondary {
    @apply text-secondary-50 bg-secondary-400 hover:bg-secondary-500 active:bg-secondary-300 focus:ring-secondary-400/25;
  }
  .btn.btn-filled.btn-accent {
    @apply text-accent-900 bg-accent-300 hover:bg-accent-400 active:bg-accent-200 focus:ring-accent-300/25;
  }
  .btn.btn-filled.btn-light {
    @apply text-primary-900 bg-primary-50 hover:bg-primary-100 active:bg-white focus:ring-primary-300/25;
  }
  .btn.btn-line {
    @apply focus:ring-2;
  }
  .btn.btn-line.btn-primary {
    @apply text-primary-900 bg-none border-2 border-primary-900 hover:border-primary-800 active:border-primary-700 focus:ring-primary-800/25;
  }
  .btn.btn-line.btn-secondary {
    @apply text-secondary-400 bg-none border-2 border-secondary-400 hover:border-secondary-500 active:border-secondary-300 focus:ring-secondary-400/25;
  }
  .btn.btn-line.btn-accent {
    @apply text-accent-300 bg-none border-2 border-accent-300 hover:border-accent-400 active:border-accent-200 focus:ring-accent-300/25;
  }
  .btn.btn-line.btn-light {
    @apply text-primary-50 bg-none border-2 border-primary-50 hover:border-primary-100 active:border-white focus:ring-primary-100/25;
  }
  .btn.btn-link {
    @apply p-0;
  }
  .btn.btn-link.btn-primary {
    @apply text-primary-900 hover:text-primary-800 active:text-primary-600 focus:text-primary-700;
  }
  .btn.btn-link.btn-secondary {
    @apply text-secondary-400 hover:text-secondary-500 active:text-secondary-200 focus:text-secondary-300;
  }
  .btn.btn-link.btn-accent {
    @apply text-accent-300 hover:text-accent-400 active:text-accent-100 focus:text-accent-200;
  }
  .btn.btn-link.btn-light {
    @apply text-primary-50 hover:text-primary-100 active:text-white focus:text-primary-200;
  }
}
/*
 * Input
 */
@layer utilities {
  .input-label {
    @apply block font-bold body-lg text-neutral-600 dark:text-neutral-200;
  }
  .input-text {
    @apply block w-full px-4 py-2 transition border rounded-none bg-neutral-50 body-sm text-neutral-500 dark:text-neutral-300 border-neutral-200 focus:ring-2 focus:ring-secondary-400/25 hover:border-primary-400;
  }
  .input-dropdown {
    @apply block w-full px-4 py-2 transition border rounded-none bg-neutral-50 focus:outline-none body-sm text-neutral-500 dark:text-neutral-300 border-neutral-200 focus:ring-2 focus:ring-secondary-400/25 hover:border-primary-400;
  }
  .input-checkbox {
    @apply w-6 h-6 transition border rounded-none bg-neutral-50 hover:checked:bg-secondary-500 checked:bg-secondary-400 border-secondary-100 focus:ring-primary-400/25 focus:ring-2 hover:border-primary-400;
  }
  .input-radio {
    @apply w-6 h-6 transition border rounded-full bg-neutral-50 hover:checked:bg-secondary-500 checked:bg-secondary-400 border-secondary-100 focus:ring-primary-400/25 focus:ring-2 hover:border-primary-400;
  }
  .input-selector-label {
    @apply body-md text-neutral-600 dark:text-neutral-200;
  }
}